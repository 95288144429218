import React from 'react';
import Layout from '../components/Layout/index';
import ServicePage from '../components/pages/services/index';

export default () => (
  <Layout>
    <ServicePage selectedTab="branding">
      <p>
        Staying informed of your audiences interactions and sentiment towards your brand is extremely valuable, and we
        will help you develop a data strategy to stay ahead of the curve.
      </p>
      <p>
        We will help you define your digital brand strategy and direction with our expertise in digital creation and
        social media. We can utilize various different social channels and email direct marketing to create traction.
      </p>
    </ServicePage>
  </Layout>
);
